class AwardsList {
    constructor(el) {
        if (!this.set(el)) return;
        this.init();
    }

    set(el) {
        if (!el) return false;

        this.root = el;
        this.yearEl = this.root.querySelectorAll('[data-awards-list-year]');
        this.gridEl = this.root.querySelector('[data-awards-list-grid]');

        this.currYear = null;

        return true;
    }

    init() {
        this.setYearButtons();
    }

    setYearButtons() {
        if (!this.yearEl) return;

        this.onYear = this.onYearClick.bind(this);
        this.yearEl.forEach(item =>
            item.addEventListener('click', this.onYear)
        );
    }

    onYearClick(event) {
        const { currentTarget } = event;
        this.currYear = currentTarget.getAttribute('data-awards-list-year');

        this.resetYears();
        this.setActiveItem(currentTarget);
        this.loadAwards();
    }

    async loadAwards() {
        const formData = new FormData();
        if (this.currYear) formData.append('year', this.currYear);
        this.gridEl.classList.add('is-loading')

        const request = await fetch(`${home_url}/wp-json/awards/v1/list`, {
            method: 'POST',
            body: formData,
        });

        const res = await request.json();
        const { html } = res;

        console.log(html, this.gridEl);

        this.gridEl.innerHTML = html;
        if (res) {
            this.gridEl.classList.remove('is-loading')
        }
    }

    resetYears() {
        if (!this.yearEl) return;
        this.yearEl.forEach(item => item.classList.remove('-active'));
    }

    setActiveItem(item) {
        if (!item) return;
        item.classList.add('-active');
    }
}

function initAwardsList() {
    const sections = document.querySelectorAll('[data-awards-list]');
    if (!sections) return;

    sections.forEach(item => new AwardsList(item));
}

if (
    document.readyState === 'complete' ||
    (document.readyState !== 'loading' && !document.documentElement.doScroll)
) {
    initAwardsList();
} else {
    document.addEventListener('DOMContentLoaded', initAwardsList);
}
